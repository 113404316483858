<template>
	<div class="settings-content">
		<template v-if="loaded">
			<div class="opacity-page">
				<div class="list-crud row-space-tbf">
					<div class="space-left"></div>
					<div class="content">
						<div class="list">
							<div class="item" v-for="department in departments" @click="viewItemFunc(department)" v-bind:class="{active: departmentSelected.id == department.id}">
								<div class="name">{{ department.name }}</div>
								<div class="actions">
									<button @click.stop="editItem(department)"><icon-edit /></button>
									<button @click.stop="showModal('delete', {type: 'department', from: 'index_departments', model: {slug: department.slug}})"><icon-trash /></button>
								</div>
							</div>
							<div class="item-empty" v-if="!departments.length">
								{{ $t('departments.no-result') }}
							</div>
						</div>
						<div class="crud" id="list-items">
							<div class="box" v-if="viewCrud == 'create'">
								<div class="header">
									<div class="icon-header"><icon-department /></div>
									<div class="name">{{$t('departments.create-new-department')}}</div>
								</div>
								<div class="form">
									<div class="input-group mb-15">
										<div class="label-header">
											<label class="label">{{ $t('general.name') }}*</label>
											<div v-if="$v.name.$error" class="error-msg">{{ $t('validator.required') }}</div>
											<div v-if="errorsBe.name" class="error-msg">{{ errorsBe.name.join(" | ") }}</div>
										</div>
										<div class="input-box bg-white" v-bind:class="{has_error: $v.name.$error}">
											<div class="icon-right" v-if="name != ''" @click.stop="name = ''"><icon-close class="icon-clear" /></div>
											<input type="text" :placeholder="$t('departments.name-ph')" class="input-text no-icon" v-model="name">
										</div>
									</div>

									<div class="input-group">
										<div class="label-header">
											<label class="label">{{ $t('general.description') }}</label>
										</div>
										<div class="input-box bg-white">
											<textarea rows="3" :placeholder="$t('departments.description-ph')" v-model="description"></textarea>
										</div>
									</div>

									<div class="input-group">
										<div class="label-header" >
											<label class="label">{{ $t('departments.choose_color') }}</label>
										</div>
										<div class="input-box flex bg-white">
											<div class="diez-character">#</div>
											<input type="text" class="input-text no-icon" v-model="color" maxlength="6" :placeholder="$t('departments.choose_color_ph')">
											<div class="form__input">
												<v-swatches
													v-model="color_code"
													popover-x="left"
													swatches="text-advanced"
												></v-swatches>
											</div>
										</div>
									</div>

									<div class="input-group">
										<div class="label-header">
											<label class="label">{{$t('departments.responsible')}}</label>
										</div>

										<div class="input-box bg-white">
											<div class="icon-left"><icon-people /></div>
											<div class="icon-right" v-if="selectedUser != ''" @click.stop="selectedUser = ''"><icon-close class="icon-clear" /></div>
											<multiselect 
											v-model="selectedUser"
											v-bind:class="{populate: selectedUser != ''}"
											:options="optionsUsers"
											:allow-empty="true"
											:show-labels="false"
											track-by="id" 
											label="name"
											@input="pushResponsible"
											>
												<template slot="placeholder" slot-scope="props">
													<span class="text">
														{{$t('departments.choose-responsible')}}
													</span>
												</template>
												<template slot="noResult">{{$t('departments.no-results')}}</template>
												<template slot="noOptions">{{$t('departments.empty-list')}}</template>
											</multiselect>
										</div>
									</div>

									<div class="input-group">
										<div class="label-header">
											<label class="label">{{$t('departments.parent-department')}}</label>
										</div>

										<div class="input-box bg-white">
											<div class="icon-left"><icon-department /></div>
											<div class="icon-right" v-if="parentDepartment != ''" @click.stop="parentDepartment = ''"><icon-close class="icon-clear" /></div>
											<multiselect 
											v-model="parentDepartment"
											v-bind:class="{populate: parentDepartment != ''}"
											:options="departments"
											:allow-empty="true"
											:show-labels="false"
											track-by="id" 
											label="name"
											>
												<template slot="placeholder" slot-scope="props">
													<span class="text">
														{{$t('departments.choose-department')}}
													</span>
												</template>
												<template slot="noResult">{{$t('departments.no-results')}}</template>
												<template slot="noOptions">{{$t('departments.empty-list')}}</template>>
											</multiselect>
										</div>
									</div>

									<div class="input-group">
										<div class="label-header">
											<label class="label">{{$t('departments.attach-users')}}</label>
										</div>

										<div class="input-box bg-white">
											<div class="icon-left"><icon-people /></div>
											<multiselect 
											:preserve-search="true"
											v-model="usersSelected"
											class="select-tags-tbf hide-tags"
											:options="optionsUsersMulti" 
											:multiple="true" 
											group-values="groupItems"
											group-label="groupName" 
											:group-select="true"
											:close-on-select="false"
											track-by="id" 
											label="name"
											:selectLabel="$t('v-multiselect.select')"
											:selectGroupLabel="$t('v-multiselect.select-all')"
											:selectedLabel="$t('v-multiselect.selected')"
											:deselectLabel="$t('v-multiselect.deselect')"
											:deselectGroupLabel="$t('v-multiselect.deselect-all')">
												<template slot="placeholder" slot-scope="props">
													<span class="text">
														{{ $t('v-multiselect.search-users') }}
													</span>
												</template>
												<template slot="selection" slot-scope="{ values, search, isOpen }">
													<span class="multiselect__single" v-if="values.length >= 1 &amp;&amp; !isOpen">{{ values.length }} {{ $t('filters.selected') }}</span>
												</template>
												<template slot="noResult">{{ $t('v-multiselect.no-results') }}</template>
											</multiselect>
										</div>
										
										<div class="textarea-selected-users">
											<span v-for="usr, index in usersSelected" class="name">
												{{ usr.name }}
												<button class="remove" @click="removeSelectedUser(index)" v-if="usr.id != selectedUser.id"><icon-close /></button>
											</span>
										</div>
									</div>

									<div class="form-submit">
										<div class="error-msg-settings" v-show="error_message!=''">{{error_message}}</div>
										<button id="buttonCreate" class="btn-tbf blue" @click="saveAction('create')">
											<div class="loader"></div>
											<span class="text">{{ $t('general.add') }}</span>
										</button>
									</div>
								</div>
							</div>
							<div class="box bg-white" v-else-if="viewCrud == 'edit'">
								<div class="header">
									<div class="icon-header"><icon-department /></div>
									<div class="name">{{ departmentSelected.name }}</div>
									<div class="action-close">
										<button class="btn-tbf white only-icon" @click="resetToList('item')">
											<div class="icon"><icon-close /></div>
										</button>
									</div>
								</div>
								<div class="form">
									<div class="input-group mb-15">
										<div class="label-header">
											<label class="label">{{ $t('general.name') }}*</label>
										</div>
										<div class="input-box" v-bind:class="{has_error: $v.name.$error}">
											<div class="icon-right" v-if="name != ''" @click.stop="name = ''"><icon-close class="icon-clear" /></div>
											<input type="text" :placeholder="$t('departments.name-ph')" class="input-text no-icon" v-model="name">
										</div>
									</div>

									<div class="input-group">
										<div class="label-header">
											<label class="label">{{ $t('general.description') }}</label>
										</div>
										<div class="input-box">
											<textarea rows="3" :placeholder="$t('departments.description-ph')" v-model="description"></textarea>
										</div>
									</div>

									<div class="input-group">
										<div class="label-header" >
											<label class="label">{{ $t('departments.choose_color') }}</label>
										</div>
										<div class="input-box flex bg-white">
											<div class="diez-character">#</div>
											<input type="text" class="input-text no-icon" v-model="color" maxlength="6" :placeholder="$t('departments.choose_color_ph')">
											<div class="form__input">
												<v-swatches
													v-model="color_code"
													popover-x="left"
													swatches="text-advanced"
												></v-swatches>
											</div>
										</div>
									</div>

									<div class="input-group">
										<div class="label-header">
											<label class="label">{{ $t('departments.responsible') }}</label>
										</div>

										<div class="input-box">
											<div class="icon-left"><icon-people /></div>
											<div class="icon-right" v-if="selectedUser != ''" @click.stop="selectedUser = ''"><icon-close class="icon-clear" /></div>
											<multiselect 
											v-model="selectedUser"
											v-bind:class="{populate: selectedUser != ''}"
											:options="optionsUsers"
											:allow-empty="true"
											:show-labels="false"
											track-by="id" 
											label="name"
											@input="pushResponsible"
											>
												<template slot="placeholder" slot-scope="props">
													<span class="text">
														{{ $t('departments.choose-responsible') }}
													</span>
												</template>
												<template slot="noResult">{{$t('departments.no-results')}}</template>
												<template slot="noOptions">{{$t('departments.empty-list')}}</template>>
											</multiselect>
										</div>
									</div>

									<div class="input-group">
										<div class="label-header">
											<label class="label">{{ $t('departments.parent-department') }}</label>
										</div>

										<div class="input-box">
											<div class="icon-left"><icon-people /></div>
											<div class="icon-right" v-if="parentDepartment != ''" @click.stop="parentDepartment = ''"><icon-close class="icon-clear" /></div>
											<multiselect 
											v-model="parentDepartment"
											v-bind:class="{populate: parentDepartment != ''}"
											:options="departments"
											:allow-empty="true"
											:show-labels="false"
											track-by="id" 
											label="name"
											>
												<template slot="placeholder" slot-scope="props">
													<span class="text">
														{{ $t('departments.choose-department') }}
													</span>
												</template>
												<template slot="noResult">{{$t('departments.no-results')}}</template>
												<template slot="noOptions">{{$t('departments.empty-list')}}</template>>
											</multiselect>
										</div>
									</div>

									<div class="input-group">
										<div class="label-header">
											<label class="label">{{ $t('departments.attach-users') }}</label>
										</div>

										<div class="input-box">
											<div class="icon-left"><icon-people /></div>
											<multiselect 
											:preserve-search="true"
											v-model="usersSelected"
											class="select-tags-tbf hide-tags"
											:options="optionsUsersMulti" 
											:multiple="true" 
											group-values="groupItems"
											group-label="groupName" 
											:group-select="true"
											:close-on-select="false"
											track-by="id" 
											label="name"
											:selectLabel="$t('v-multiselect.select')"
											:selectGroupLabel="$t('v-multiselect.select-all')"
											:selectedLabel="$t('v-multiselect.selected')"
											:deselectLabel="$t('v-multiselect.deselect')"
											:deselectGroupLabel="$t('v-multiselect.deselect-all')">
												<template slot="placeholder" slot-scope="props">
													<span class="text">
														{{ $t('v-multiselect.search-users') }}
													</span>
												</template>
												<template slot="selection" slot-scope="{ values, search, isOpen }">
													<span class="multiselect__single" v-if="values.length >= 1 &amp;&amp; !isOpen">{{ values.length }} {{ $t('filters.selected') }}</span>
												</template>
												<template slot="noResult">{{ $t('v-multiselect.no-results') }}</template>
											</multiselect>
										</div>

										<div class="textarea-selected-users">
											<span v-for="(usr, index) in usersSelected" class="name">
												{{ usr.name }}
												<button class="remove" @click="removeSelectedUser(index)"  v-if="usr.id != selectedUser.id"><icon-close /></button>
											</span>
										</div>
									</div>

									<div class="form-submit">
										<div class="error-msg-settings" v-show="error_message!=''">{{error_message}}</div>
										<button id="buttonUpdate" class="btn-tbf blue" @click="saveAction('update')">
											<div class="loader"></div>
											<span class="text">{{ $t('general.update') }}</span>
										</button>
									</div>
								</div>
							</div>
							<div class="box bg-white" v-else-if="viewCrud == 'view'">
								<div class="header">
									<div class="icon-header"><icon-department /></div>
									<div class="name">{{ departmentSelected.name }}</div>
									<div class="action-close">
										<button class="btn-tbf blue only-icon" @click="editItem(departmentSelected)">
											<div class="icon"><icon-edit /></div>
										</button>
										<button class="btn-tbf white only-icon" @click="resetToList('item')">
											<div class="icon"><icon-close /></div>
										</button>
									</div>
								</div>
								<div class="data-item">
									<div class="description">{{ departmentSelected.description }}</div>
									<div class="label" v-if="departmentSelected.color_code">{{ $t('departments.choose_color') }}</div>
									<div class="text color_code" v-if="departmentSelected.color_code">
										<div class="color_code_circle" :style="`background-color: ${departmentSelected.color_code};`"></div>
										{{ departmentSelected.color_code }}
									</div>

									<div class="label" v-if="departmentSelected.responsible">{{ $t('departments.responsible') }}</div>
									<div class="text" v-if="departmentSelected.responsible">{{ departmentSelected.responsible.first_name + ' ' + departmentSelected.responsible.last_name }}</div>

									<div class="label" v-if="departmentSelected.parent_department">{{ $t('departments.parent-department') }}</div>
									<div class="text" v-if="departmentSelected.parent_department">{{ departmentSelected.parent_department.name }}</div>

									<div class="label" v-if="departmentSelected.users.length">{{ $t('departments.members') }}</div>
									<div class="text" v-if="departmentSelected.users.length">
										<span class="user_name" v-for="(user, index) in optionsUsers.filter(x => departmentSelected.users.includes(x.id))">
											{{ user.name }}<span v-if="index != Object.keys(optionsUsers.filter(x => departmentSelected.users.includes(x.id) )).length - 1">, </span> 
										</span>
									</div>

								</div>
							</div>
						</div>
					</div>
					<div class="space-right"></div>
				</div>
			</div>
		</template>
		<loader-settings-page v-else/>
	</div>
</template>

<script>
	import IconClose from '../../Icons/Close'
	import IconPeople from '../../Icons/User'
	import IconEdit from '../../Icons/Edit'
	import IconTrash from '../../Icons/Trash'
	import IconDepartment from '../../Icons/Department'
	import LoaderSettingsPage from '../../PagesLoaders/SettingsPage'
	import { required } from 'vuelidate/lib/validators'
	import VSwatches from 'vue-swatches'
	import 'vue-swatches/dist/vue-swatches.css'

	export default {
		components: {
			IconClose,
			IconEdit,
			IconTrash,
			IconPeople,
			IconDepartment,
			LoaderSettingsPage,
			VSwatches
		},
		computed: {
			soft_organigram() {
				return this.$store.getters['applications/getApplication']('Organigrama');
			}
		},
		watch: {
			color_code (newValue, oldValue) {
				this.color = newValue.substring(1);
			},
			color (newValue, oldValue) {
				this.color_code = newValue ? `#${newValue}` : ''
			}
		},
		data() {
			return {
				loaded: false,
				parentDepartment: '',
				viewCrud: 'create',
				departments: [],
				departmentSelected: '',
				name: '',
				description: '',
				optionsUsers: [],
			  	selectedUser: '',
			  	usersSelected: [],
			  	optionsUsersMulti: [
					{
						groupName: this.$t('v-multiselect.users'),
						groupItems: []
					}
				],
				error_message: '',
				errorsBe: {},
				color: '',
				color_code: ''
			}
		},
		async mounted(){
			await this.getUsers()
			await this.getDepartments()

			setTimeout(() => {
				var title = this.$t('settings-navbar.departments');
				this.$root.$emit("navbar_title", title);
			}, 0)

			this.$root.$on('refreshDepartmentsIndex', () => {
				this.resetToList();
				this.getDepartments()
			})

			if(!this.$auth.user().rights.admins_instance_crud){
				this.$router.push({name: 'forbbiden'})
			}
		},
		validations: {
			name: {required}
		},
		methods: {
			customLabel({ first_name, last_name }){
				return `${first_name} ${last_name}`
			},
			pushResponsible(){
				this.usersSelected.includes(this.selectedUser) ? "" :  this.usersSelected.push(this.selectedUser)
				this.optionsUsersMulti[0].groupItems.filter( el => el.$isDisabled ).map(el => el.$isDisabled = false )
				this.optionsUsersMulti[0].groupItems.find( el => el.id == this.selectedUser.id ).$isDisabled = true
			},
			removeSelectedUser(index){
				this.usersSelected.splice(index,1);
			},
			async getUsers(){
				await axios.get( `instances/${this.$auth.user().instance.id}/filter`, { params: {users: true}})
				.then(({data}) => {
					this.optionsUsers = data.data.users
					this.optionsUsersMulti[0].groupItems = data.data.users
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			async getDepartments(){
				await axios.get(this.$auth.user().instance.id + '/departments')
				.then(({data}) => {
					this.departments = data.data
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => {
					this.loaded = true
					setTimeout(() => {
						$('.opacity-page').addClass('show')
					}, 0)
				})
			},
			saveAction(type){
				var buttonName = `button${ type == 'create' ? 'Create' : 'Update'}`
				var btnSubmit = document.getElementById(buttonName)
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				btnSubmit.disabled = true
				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				this.error_message = '';

				this.$v.$touch()
				if(!this.$v.$invalid){
					var selectedUsers = this.usersSelected.length ? this.usersSelected.map(function(a) {return a.id;}) : ''

					var objData = {
						name: this.name.charAt(0).toUpperCase() + this.name.slice(1),
						description: this.description,
						responsible_id: this.selectedUser ? this.selectedUser.id : '',
						department_id: this.parentDepartment ? this.parentDepartment.id : '',
						users: selectedUsers,
						color_code: this.color_code
					}

					if(type == 'create'){
						this.createDepartment(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}else if(type == 'update'){
						this.updateDepartment(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}

				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
			createDepartment(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.post('/departments/store', objData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						this.getDepartments()
						this.$v.$reset()
						setTimeout(()=>{
							this.resetToList()
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('general.add')
							this.departmentSelected = data.data;
							this.viewCrud = 'view'
						}, 1000)
					}, 300)
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					this.errorsBe = error.response.data.errors
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.add')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
						}, 1000)
					}, 300)
				})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			},
			updateDepartment(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.patch(`/departments/${this.departmentSelected.slug}`, objData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						this.getDepartments()
						this.$v.$reset()
						setTimeout(()=>{
							this.resetToList()
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('general.add')
							this.departmentSelected = this.departments.find(el => el.id == this.departmentSelected.id)
							this.viewCrud = 'view'
						}, 1000)
					}, 300)
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					this.errorsBe = error.response.data.errors
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
						}, 1000)
					}, 300)
				})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			},
			editItem(department){
				if(this.departments.find(el => el.$isDisabled)){
					this.departments.find(el => el.$isDisabled).$isDisabled = false
				}
				this.scrollTop()
				this.viewCrud = 'edit';
				
				this.departmentSelected = department;

				this.name = department.name;
				this.color_code = department.color_code ? department.color_code : '';
				this.color = department.color_code ? department.color_code.substring(1) : '';
				this.description = department.description;
				this.selectedUser = department.responsible ? this.optionsUsers.find(el => el.id == department.responsible.id) : '';

				this.usersSelected = department.users.length ? this.optionsUsersMulti[0].groupItems.filter(x => department.users.includes(x.id)) : [];
				this.parentDepartment = department.parent_department ? this.departments.find(el => el.id == department.parent_department.id) : '';

				this.departments.find(el => el.id == department.id).$isDisabled = true;
			},
			resetToList(from){
				this.viewCrud = 'create'
				if(from == 'item') {
					this.departmentSelected = ''
				}
				this.name = '';
				this.color = '';
				this.color_code = '';
				this.description = '';
				this.selectedUser = '';
				this.parentDepartment = '';
				this.usersSelected = [];

				if(this.departments.find(el => el.$isDisabled)){
					this.departments.find(el => el.$isDisabled).$isDisabled = false
				}
			},
			viewItemFunc(item){
				this.scrollTop()
				this.viewCrud = 'view'
				this.departmentSelected = item
			},
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			},
			scrollTop() {
				var top_value = {scrollTop:$('#list-items').position().top}
				if(top_value.scrollTop !== 135){
					$('div').animate({scrollTop:0}, 'slow');
				}
			}
		}
	};
</script>